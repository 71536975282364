// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-home-tsx": () => import("./../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-reg-tsx": () => import("./../src/templates/reg.tsx" /* webpackChunkName: "component---src-templates-reg-tsx" */),
  "component---src-templates-tournament-tsx": () => import("./../src/templates/tournament.tsx" /* webpackChunkName: "component---src-templates-tournament-tsx" */),
  "component---src-templates-match-tsx": () => import("./../src/templates/match.tsx" /* webpackChunkName: "component---src-templates-match-tsx" */),
  "component---src-templates-admin-tsx": () => import("./../src/templates/admin.tsx" /* webpackChunkName: "component---src-templates-admin-tsx" */),
  "component---src-templates-admin-reg-tsx": () => import("./../src/templates/admin/reg.tsx" /* webpackChunkName: "component---src-templates-admin-reg-tsx" */),
  "component---src-templates-admin-tournament-tsx": () => import("./../src/templates/admin/tournament.tsx" /* webpackChunkName: "component---src-templates-admin-tournament-tsx" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */)
}

